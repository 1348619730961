import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/SEO"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Peragraphs from "../components/Peragraphs"
import Text from "../components/Text"
// import { Helmet } from "react-helmet"
import { urlFix } from '../utils/helpers';



const Homepage = ({ data }) => {

  // console.log(data);

  var node = {};
  var paragraphs = [];
  node = data.drupal.pageNode;




  // console.log(node);
  if(data.drupal.pageNode.fieldContent && data.drupal.pageNode.fieldContent !== null){
    paragraphs = node.fieldContent;
  }

  var fieldApplicationTagline = "";
  if(node.fieldApplicationTagline !== null && node.fieldApplicationTagline.value){
    fieldApplicationTagline = node.fieldApplicationTagline.value;
  }


  // var fieldApplicationImageView = "";
  var fieldApplicationImageViewArray = [];
  
  if(node.fieldApplicationView !== null ){
    node.fieldApplicationView.forEach((value) => {
      fieldApplicationImageViewArray.push({
        thumbnail: value.entity.thumbnail.url,
        alt: value.entity.thumbnail.alt,
      });
    })
    // if(node.fieldApplicationView[0].entity !== null && node.fieldApplicationView[0].entity.thumbnail && node.fieldApplicationView[0].entity.thumbnail.url){
    //   fieldApplicationImageView = node.fieldApplicationView[0].entity.thumbnail.url;
    // }
  }

  // console.log(fieldApplicationImageViewArray);

  var logo = "";

  if(node.fieldApplicationLogo !== null ){
    if(node.fieldApplicationLogo.entity !== null && node.fieldApplicationLogo.entity.thumbnail && node.fieldApplicationLogo.entity.thumbnail.url){
      logo = node.fieldApplicationLogo.entity.thumbnail.url;
    }
  }

  var varients = node.fieldApplicationVersions;

  

  // console.log(node);
  return (
      <div className={ node.language.id === "en" ? "EN" :"UR"}>
        <Seo post={{
          title: node.fieldApplicationName ,
          description: fieldApplicationTagline,
          image: fieldApplicationImageViewArray[0].thumbnail,
          type: 'website'
        }} />
        <Header lang={ node.language.id === "en" ? "EN" :"UR"} />
        <section>
          <div className="max-w-6xl mx-auto px-4 sm:px-6 mt-20">

          <div id="header-logo" className="md:flex items-center mb-4  items-start"> 
            <img alt="avatar" className="w-12 md:w-48 h-12 md:h-48 ml-4 md:ml-0 self-start" src={logo} />
            <div id="header-text" className="leading-5 ml-6 sm">
              <div className="text-4xl md:text-4xl font-extrabold leading-tighter tracking-tighter mb-4 text-brand" data-aos="zoom-y-out">
                <Text text={node.fieldApplicationName} key="fieldApplicationTagline"/>
                <div className="text-2xl md:text-3xl leading-tighter tracking-tighter mb-4 text-brand" data-aos="zoom-y-out">
                  <Text text={fieldApplicationTagline} key="fieldApplicationTagline"/>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        <section>
          <div className="max-w-6xl mx-auto px-4 sm:px-6 mb-12 wide hidden">

            <div className="pt-12 pb-6 narrow">

              <div className="text-center pb-6 md:pb-8 font-sans  text-brand">
                <div className="my-4 text-center">
                  <h3 className="text-3xl font-bold">Variants</h3>
                </div>
                <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 sm:gap-1">
                  {
                    varients.map((varient) => {
                      return(<div className="group shadow flex items-center bg-gray-100 bg-opacity-40 shadow-xl gap-2 px-6 py-5 rounded-lg mt-5 cursor-pointer hover:bg-opacity-100 transition">
                      <span className={urlFix(varient.url.path)} >
                        <span>{varient.title}</span>
                        <span className="text-xs text-blue-300 hidden">Typescript</span>
                      </span>
                      <div>
                        <i className="fa fa-chevron-right opacity-0 group-hover:opacity-100 transform -translate-x-1 group-hover:translate-x-0 block transition"></i>
                      </div>
                    </div>)
                    })
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="wide p-6" data-aos="zoom-y-out" data-aos-delay="450">
          <h3 className="text-xl md:text-2xl lg:text-3xl font-brand font-sans text-brand font-bold mb-5 text-center">Screens</h3>
          <div className="p-4 gap-2 grid grid-cols-3">
            {
              fieldApplicationImageViewArray.map((object, index) => {
                return (<img className="object-cover h-48 w-full border-2 rounded-lg" src={object.thumbnail} alt={object.alt} key={index} />)
              })
            }
            
          </div>
        </div>
        <Peragraphs elements={paragraphs} />
        <Footer lang={ node.language.id === "en" ? "EN" :"UR"} />
    </div>
  )
}

export const query = graphql`
  query($id : String!, $language: DRUPAL_LanguageId!){
    drupal {
      pageNode: nodeById(id: $id, language: $language) {
        entityId
        entityLabel
        entityType
        entityUrl {
          path
        }
        
        language: entityLanguage {
          id
        }
        nid
        title
        sticky
        ... on DRUPAL_NodeApplications {
          nid
          uuid
          entityId
          entityLabel
          entityBundle
          fieldApplicationLogo {
            entity {
              thumbnail {
                alt
                height
                title
                url
                width
              }
            }
          }
          fieldApplicationName
          fieldApplicationTagline {
            format
            value
          }
          fieldApplicationView {
            entity {
              thumbnail {
                title
                url
                width
                alt
                height
              }
            }
          }
          title
          fieldApplicationVersions {
            title
            url {
              path
            }
          }
          fieldContent: fieldApplicationPageContent {
            entity {
              entityId
              entityLabel
              entityBundle
              status
              ... on DRUPAL_ParagraphUiHero {
                id
                entityId
                entityLabel
                entityType
                type {
                  targetId
                  entity {
                    ... on DRUPAL_TaxonomyTermHeroTypes {
                      tid
                      uuid
                      entityId
                      entityType
                      name
                    }
                  }
                }
                fieldUiHeroTitle {
                  entity {
                    entityId
                    entityLabel
                    entityBundle
                    ... on DRUPAL_ParagraphTitle {
                      id
                      fieldTitleAndSummary {
                        processed
                        summaryProcessed
                      }
                    }
                  }
                }
                fieldUiHeroTextSettings {
                  entity {
                    entityId
                    entityBundle
                    entityType
                    ... on DRUPAL_ParagraphTextStyling {
                      id
                      fieldHeadingColour {
                        entity {
                          entityId
                          entityLabel
                          entityBundle
                          entityType
                          ... on DRUPAL_TaxonomyTermColours {
                            tid
                            uuid
                            name
                          }
                        }
                      }
                      fieldParagraphColour {
                        entity {
                          entityId
                          entityLabel
                          entityBundle
                          ... on DRUPAL_TaxonomyTermColours {
                            tid
                            uuid
                            name
                          }
                        }
                      }
                    }
                  }
                }
                fieldUiHeroDisplayTypes {
                  entity {
                    entityId
                    entityLabel
                    entityType
                    name
                    status
                    ... on DRUPAL_TaxonomyTermHeroTypes {
                      tid
                      uuid
                      name
                    }
                    entityBundle
                  }
                }
                fieldUiHeroBackgroundSetting {
                  targetId
                  targetRevisionId
                  entity {
                    entityId
                    entityLabel
                    entityType
                    entityBundle
                    ... on DRUPAL_ParagraphBackgroundSettings {
                      id
                      entityBundle
                      entityId
                      entityLabel
                      entityType
                      fieldBackgroundColour {
                        entity {
                          entityId
                          entityLabel
                        }
                      }
                      fieldBackgroundImage {
                        width
                        url
                        title
                        height
                        alt
                      }
                      fieldUiHeroBackgroundOverlay {
                        entity {
                          entityId
                          entityLabel
                          ... on DRUPAL_ParagraphBackgroundOverlay {
                            id
                            entityBundle
                            entityId
                            entityLabel
                            entityType
                            fieldBackgroundOverlayColour {
                              entity {
                                ... on DRUPAL_TaxonomyTermColours {
                                  tid
                                  uuid
                                  entityId
                                  entityLabel
                                  name
                                  entityBundle
                                }
                              }
                            }
                            fieldBackgroundOverlayOpacity {
                              entity {
                                entityId
                                entityBundle
                                entityLabel
                                entityType
                                ... on DRUPAL_TaxonomyTermOpacityLevels {
                                  tid
                                  uuid
                                  entityId
                                  entityBundle
                                  entityLabel
                                  entityType
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldImageSelector {
                  entity {
                    entityId
                    entityLabel
                    entityType
                    name
                    thumbnail {
                      alt
                      title
                      url
                      width
                      height
                    }
                    entityBundle
                  }
                }
                entityBundle
                fieldButtonsGroup {
                  entity {
                    entityId
                    entityLabel
                    entityType
                    status
                    entityBundle
                    ... on DRUPAL_ParagraphButton {
                      id
                      entityId
                      entityLabel
                      fieldButt {
                        title
                        uri
                        url {
                          path
                        }
                      }
                      fieldButtonTitle {
                        value
                      }
                      fieldButtonType {
                        entity {
                          entityId
                          entityType
                          ... on DRUPAL_TaxonomyTermButtonTypes {
                            tid
                            uuid
                            entityId
                            entityLabel
                            name
                            entityBundle
                          }
                          entityBundle
                        }
                      }
                      entityBundle
                    }
                  }
                }
              }
              ... on DRUPAL_ParagraphUiListingSelectedNodes {
                id
                entityId
                entityLabel
                entityBundle
                fieldFieldShowAuthorUnderCo
                fieldShowTypeOnContent
                fieldStaticListingType {
                  entity {
                    name
                    entityId
                    entityLabel
                    entityBundle
                  }
                }
                fieldSelectedNodeButtonGroup {
                  entity {
                    entityId
                    entityLabel
                    entityType
                    status
                    entityBundle
                    ... on DRUPAL_ParagraphButtonsGroup {
                      id
                      entityId
                      entityLabel
                      entityBundle
                      fieldButtons {
                        entity {
                          entityId
                          entityLabel
                          entityBundle
                          ... on DRUPAL_ParagraphButton {
                            id
                            entityId
                            entityBundle
                            entityLabel
                            fieldButt {
                              url {
                                path
                              }
                            }
                            fieldButtonTitle {
                              value
                            }
                            fieldButtonType {
                              entity {
                                entityId
                                entityLabel
                                entityBundle
                                ... on DRUPAL_TaxonomyTermButtonTypes {
                                  tid
                                  uuid
                                  entityId
                                  entityLabel
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldUiListingSelectdTextSe {
                  entity {
                    entityId
                    entityLabel
                    entityBundle
                    ... on DRUPAL_ParagraphTextStyling {
                      id
                      entityBundle
                      entityId
                      entityLabel
                      fieldParagraphColour {
                        entity {
                          entityId
                          entityBundle
                          entityLabel
                        }
                      }
                      fieldHeadingColour {
                        entity {
                          entityId
                          entityLabel
                          entityBundle
                        }
                      }
                    }
                  }
                }
                fieldUiListingSelectedTitle {
                  entity {
                    entityId
                    entityLabel
                    entityBundle
                    ... on DRUPAL_ParagraphTitle {
                      id
                      entityId
                      entityLabel
                      entityBundle
                      fieldTitleAndSummary {
                        processed
                        summaryProcessed
                      }
                    }
                  }
                }
                fieldUiSeleclistitBackground {
                  entity {
                    entityId
                    entityLabel
                    entityBundle
                    ... on DRUPAL_ParagraphBackgroundSettings {
                      id
                      fieldBackgroundColour {
                        entity {
                          entityId
                          entityBundle
                          entityLabel
                          ... on DRUPAL_TaxonomyTermColours {
                            tid
                            uuid
                            entityBundle
                            entityId
                            entityLabel
                            name
                          }
                        }
                      }
                      fieldBackgroundImage {
                        title
                        alt
                        height
                        url
                        width
                      }
                      fieldUiHeroBackgroundOverlay {
                        entity {
                          entityBundle
                          entityId
                          entityLabel
                          ... on DRUPAL_ParagraphBackgroundOverlay {
                            id
                            entityBundle
                            entityId
                            entityLabel
                            fieldBackgroundOverlayColour {
                              entity {
                                entityId
                                entityBundle
                                entityLabel
                                ... on DRUPAL_TaxonomyTermColours {
                                  tid
                                  uuid
                                  entityId
                                  entityBundle
                                  entityLabel
                                  name
                                }
                              }
                            }
                            fieldBackgroundOverlayOpacity {
                              entity {
                                entityBundle
                                entityId
                                entityLabel
                                ... on DRUPAL_TaxonomyTermOpacityLevels {
                                  tid
                                  uuid
                                  entityBundle
                                  entityId
                                  entityLabel
                                  name
                                }
                              }
                            }
                          }
                          ... on DRUPAL_ParagraphBackgroundSettings {
                            id
                            entityBundle
                            entityId
                            entityLabel
                            fieldBackgroundColour {
                              entity {
                                entityId
                                entityLabel
                                entityBundle
                                ... on DRUPAL_TaxonomyTermColours {
                                  tid
                                  uuid
                                  entityBundle
                                  entityId
                                  entityLabel
                                  name
                                }
                              }
                            }
                            fieldBackgroundImage {
                              alt
                              height
                              title
                              url
                              width
                            }
                            fieldUiHeroBackgroundOverlay {
                              entity {
                                entityId
                                entityLabel
                                entityBundle
                                ... on DRUPAL_ParagraphBackgroundOverlay {
                                  id
                                  entityId
                                  entityLabel
                                  entityBundle
                                  fieldBackgroundOverlayColour {
                                    entity {
                                      entityBundle
                                      entityId
                                      entityLabel
                                      ... on DRUPAL_TaxonomyTermColours {
                                        tid
                                        uuid
                                        entityBundle
                                        entityId
                                        entityLabel
                                        name
                                      }
                                    }
                                  }
                                  fieldBackgroundOverlayOpacity {
                                    entity {
                                      entityId
                                      entityLabel
                                      entityBundle
                                      ... on DRUPAL_TaxonomyTermOpacityLevels {
                                        tid
                                        uuid
                                        entityBundle
                                        entityId
                                        entityLabel
                                        name
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                fieldUiSelectedNodesLinks {
                  entity {
                    entityId
                    entityBundle
                    entityLabel
                    ... on DRUPAL_ParagraphStaticList {
                      id
                      entityId
                      entityLabel
                      entityBundle
                      entityOwner {
                        entityId
                        entityLabel
                        entityBundle
                        name
                      }
                      status
                      type {
                        entity {
                          entityBundle
                          entityId
                          entityLabel
                          ... on DRUPAL_TaxonomyTermListingDisplayTypes {
                            tid
                            uuid
                            entityId
                            entityBundle
                            entityLabel
                            name
                          }
                        }
                      }
                      fieldStaticListNodes {
                        entity {
                          entityId
                          entityLabel
                          entityBundle
                          status
                          title
                          entityUrl {
                            path
                          }
                          ... on DRUPAL_NodeArticle {
                            nid
                            uuid
                            body {
                              summaryProcessed
                            }
                            entityId
                            entityLabel
                            entityBundle
                            fieldImage {
                              alt
                              height
                              targetId
                              title
                              url
                              width
                            }
                          }
                          ... on DRUPAL_NodeApplications {
                            nid
                            uuid
                            entityId
                            entityLabel
                            entityBundle
                            fieldApplicationLogo {
                              entity {
                                name
                                thumbnail {
                                  alt
                                  url
                                  width
                                  height
                                }
                              }
                            }
                            fieldApplicationName
                            fieldApplicationTagline {
                              value
                            }
                          }
                          ... on DRUPAL_NodeApplicationFeatures {
                            nid
                            uuid
                            entityId
                            entityLabel
                            entityBundle
                            fieldApplicationFeatureImage {
                              entity {
                                name
                                thumbnail {
                                  alt
                                  url
                                  width
                                  height
                                }
                              }
                            }
                            title
                            body {
                              value
                            }
                          }
                          ... on DRUPAL_NodeServices {
                            nid
                            uuid
                            entityBundle
                            entityId
                            entityLabel
                            fieldServiceFeatureImage {
                              entity {
                                entityId
                                entityLabel
                                entityBundle
                                thumbnail {
                                  alt
                                  height
                                  title
                                  url
                                  width
                                }
                              }
                            }
                            title
                            body {
                              summaryProcessed
                            }
                          }
                          ... on DRUPAL_NodeCompanyPages {
                            nid
                            uuid
                            entityBundle
                            entityId
                            entityLabel
                            fieldCompanyFeatureImage {
                              entity {
                                entityId
                                entityLabel
                                entityBundle
                                thumbnail {
                                  alt
                                  height
                                  title
                                  url
                                  width
                                }
                              }
                            }
                            title
                            body {
                              value
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on DRUPAL_ParagraphUiHubspotForm {
                id
                entityId
                entityLabel
                fieldHubspotForm {
                  formId
                  formTitle
                }
                entityRendered(mode: PREVIEW)
                fieldUiHubspotFormHeading {
                  entity {
                    entityId
                    entityLabel
                    ... on DRUPAL_ParagraphTitle {
                      id
                      entityId
                      entityLabel
                      fieldTitleAndSummary {
                        processed
                        summaryProcessed
                      }
                    }
                  }
                }
              }
              ... on DRUPAL_ParagraphImageTeaserCard {
                id
                entityId
                entityLabel
                entityBundle
                fieldTeaserAlignment {
                  entity {
                    entityId
                    entityLabel
                  }
                }
                fieldTeaserDescription {
                  format
                  value
                }
                fieldTeaserHeading
                fieldTeaserLink {
                  uri
                  title
                }
                fieldTeserImage {
                  entity {
                    thumbnail {
                      alt
                      height
                      targetId
                      title
                      url
                      width
                    }
                  }
                }
              }
            }
            
          }
        }
      }
    }
  }
`

export default Homepage
